<template>
  <div class="content-col col-height">
    <div class="view-box">
      <div class="view-box-title">厕改档案</div>
    </div>
    <div class="view-content-box">
      <div class="data-card" style="margin-left: 0px;">
        <div class="content">
          <span class="card-title">总户数</span><br>
          <span class="card-value">16507<span class="unit"> 户</span></span><br>
        </div>
      </div>
      <div class="data-card">
        <div class="content">
          <span class="card-title">三格化粪池</span><br>
          <span class="card-value">9652<span class="unit"> 户</span></span><br>
        </div>
      </div>
      <div class="data-card">
        <div class="content">
          <span class="card-title">年计划清运量</span><br>
          <span class="card-value">6<span class="unit"> 万m³</span></span><br>
        </div>
      </div>
      <div class="data-card">
        <div class="content">
          <span class="card-title">泵站</span><br>
          <span class="card-value">34<span class="unit"> 座</span></span><br>
        </div>
      </div>
      <div class="data-card">
        <div class="content">
          <span class="card-title">集污池</span><br>
          <span class="card-value">12<span class="unit"> 座</span></span><br>
        </div>
      </div>
      <div class="data-card">
        <div class="content">
          <span class="card-title">单翁</span><br>
          <span class="card-value">3512<span class="unit"> 户</span></span><br>
        </div>
      </div>
      <div class="data-card">
        <div class="content">
          <span class="card-title">清运车辆</span><br>
          <span class="card-value">5<span class="unit"> 辆</span></span><br>
        </div>
      </div>
      <div class="data-card" style="margin-left: 0px;">
        <div class="content">
          <span class="card-title">污水处理站</span><br>
          <span class="card-value">5<span class="unit"> 座</span></span><br>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'LeftTwo',
    components: {},
    data() {
      return {
        gatherSpotStatistics: {
          totalNum: 0,
          jNum: 0,
          sNum: 0,
          dNum: 0,
          carNum: 0
        }
      }
    },
    created() {
      this.getStatisticsData()
    },
    methods: {
      getStatisticsData() {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.datavStatistics,
          data: {
            divisionNum: '22',
          },
          noTips: true,
          success: (data) => {
            this.gatherSpotStatistics = data.body
          }
        })
      }
    }
  }
</script>