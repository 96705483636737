<template>
  <div>
    <div style="z-index: 100;position: relative;top: 12px;left: 60px;">
      <div
        style="width: 236px;float: left;text-align:left;font-size: large;font-weight: bold;line-height: 30px;padding-left: 6px;">
        <span @click="click">项目基本情况</span>
      </div>
      <div
        style=" height: 180px;width: 100%;top: 16px;left: -35px; padding: 15px;line-height: 29px;font-size: 13px;position: absolute;z-index: 999">
        <div style="text-indent: 2em;text-align: left;">
          1.服务的范围:洪广镇、立岗镇、金贵镇、习岗镇、常信乡、南梁台子农牧场、京星农牧场，涉<br/>
          及以上四镇一乡两个农牧场的<span class="strong">12</span>座集污池、
          2019-2022年改厕农户<span class="strong">16057户</span>(三格化粪池式<span class="strong">9652户</span>、<br/>
          单瓮<span class="strong">3512户</span>、
          集中管网式<span class="strong">2893户</span>)。根据历年实际清运情况，清运量约为<span class="strong">6</span>万吨/年。
          <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.服务的内容及标准:一是集污池清运、维护，确保污水及时清运不外溢。二是及时对农村新<br/>
          建卫生厕所进行污水清运，包括农户单瓮、三格化粪池污水的清运。三是农村户厕运维管护调度 <br/>
          中心的维护运行，包括及时接听服务电话，合理快速调度清运和维护人员及车辆，安排清运任务， <br/>
          认真核查清运数据，做好记录统计等。
        </div>
      </div>
      <!--      <dv-decoration-3 style="height: 32px;width: 236px"/>-->
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
  import { Scene, PointLayer, LineLayer, PolygonLayer } from '@antv/l7'
  import { GaodeMap } from '@antv/l7-maps'
  import SERVICE_URLS from '@/api/service.url'

  const pointData = {
    'type': 'FeatureCollection',
    'features': [{
      'type': 'Feature',
      'properties': {},
      'geometry': {
        'type': 'MultiPoint',
        'coordinates': [
          [105.97000122070312, 37.61967039695652],
          [105.63079833984375, 37.292628004510924],
          [105.69259643554688, 36.99377838872517],
          [106.6181946, 38.62223528],
          [106.4870453, 38.49659352],
          [106.3517761, 38.45251326]
        ]
      }
    }]
  }
  const lineData = {
    'type': 'FeatureCollection',
    'features': [{
      'type': 'Feature',
      'properties': {},
      'geometry': {
        'type': 'LineString',
        'coordinates': [
          [105.97000122070312, 37.61967039695652],
          [105.63079833984375, 37.292628004510924],
          [105.69259643554688, 36.99377838872517],
          [106.6181946, 38.62223528],
          [106.4870453, 38.49659352],
          [106.3517761, 38.45251326]
        ]
      }
    }]
  }
  const polygonData = {
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'properties': {
          'adcode': 640122,
          'name': '贺兰县',
          'center': [
            106.345904,
            38.554563
          ],
          'centroid': [
            106.251819,
            38.684216
          ],
          'childrenNum': 0,
          'level': 'district',
          'acroutes': [
            100000,
            640000,
            640100
          ],
          'parent': {
            'adcode': 640100
          }
        },
        'geometry': {
          'type': 'MultiPolygon',
          'coordinates': [
            [
              [
                [
                  106.003309,
                  38.875169
                ],
                [
                  106.00569,
                  38.875632
                ],
                [
                  106.009962,
                  38.875637
                ],
                [
                  106.013772,
                  38.874496
                ],
                [
                  106.021037,
                  38.872564
                ],
                [
                  106.024421,
                  38.871768
                ],
                [
                  106.030448,
                  38.870112
                ],
                [
                  106.034927,
                  38.86961
                ],
                [
                  106.038161,
                  38.869955
                ],
                [
                  106.043073,
                  38.86978
                ],
                [
                  106.04748,
                  38.86921
                ],
                [
                  106.050707,
                  38.868469
                ],
                [
                  106.053131,
                  38.867205
                ],
                [
                  106.055761,
                  38.865034
                ],
                [
                  106.062208,
                  38.859033
                ],
                [
                  106.065208,
                  38.856964
                ],
                [
                  106.070183,
                  38.856517
                ],
                [
                  106.073034,
                  38.854111
                ],
                [
                  106.074946,
                  38.853188
                ],
                [
                  106.079175,
                  38.850731
                ],
                [
                  106.081386,
                  38.850097
                ],
                [
                  106.087819,
                  38.847576
                ],
                [
                  106.090093,
                  38.846299
                ],
                [
                  106.092134,
                  38.844524
                ],
                [
                  106.094252,
                  38.843042
                ],
                [
                  106.095858,
                  38.842293
                ],
                [
                  106.100244,
                  38.841369
                ],
                [
                  106.109691,
                  38.840372
                ],
                [
                  106.124966,
                  38.839414
                ],
                [
                  106.125912,
                  38.838954
                ],
                [
                  106.126793,
                  38.837404
                ],
                [
                  106.127213,
                  38.832503
                ],
                [
                  106.128307,
                  38.8289
                ],
                [
                  106.129843,
                  38.826154
                ],
                [
                  106.130937,
                  38.824829
                ],
                [
                  106.134122,
                  38.822265
                ],
                [
                  106.135458,
                  38.82155
                ],
                [
                  106.137903,
                  38.818521
                ],
                [
                  106.141699,
                  38.817469
                ],
                [
                  106.146654,
                  38.81637
                ],
                [
                  106.155702,
                  38.815868
                ],
                [
                  106.164175,
                  38.815544
                ],
                [
                  106.170445,
                  38.815633
                ],
                [
                  106.17402,
                  38.815382
                ],
                [
                  106.176792,
                  38.814909
                ],
                [
                  106.179273,
                  38.814211
                ],
                [
                  106.184,
                  38.811676
                ],
                [
                  106.185386,
                  38.811208
                ],
                [
                  106.189623,
                  38.810726
                ],
                [
                  106.194655,
                  38.810701
                ],
                [
                  106.196219,
                  38.810501
                ],
                [
                  106.196447,
                  38.805265
                ],
                [
                  106.200754,
                  38.803178
                ],
                [
                  106.202574,
                  38.802939
                ],
                [
                  106.207535,
                  38.803263
                ],
                [
                  106.21362,
                  38.806675
                ],
                [
                  106.220352,
                  38.809721
                ],
                [
                  106.223429,
                  38.811434
                ],
                [
                  106.230303,
                  38.814202
                ],
                [
                  106.231831,
                  38.813913
                ],
                [
                  106.236047,
                  38.809201
                ],
                [
                  106.239515,
                  38.800545
                ],
                [
                  106.240461,
                  38.799058
                ],
                [
                  106.241989,
                  38.798185
                ],
                [
                  106.244619,
                  38.797895
                ],
                [
                  106.247477,
                  38.798116
                ],
                [
                  106.25091,
                  38.79919
                ],
                [
                  106.255374,
                  38.80008
                ],
                [
                  106.258302,
                  38.799795
                ],
                [
                  106.265233,
                  38.796161
                ],
                [
                  106.270927,
                  38.79265
                ],
                [
                  106.27581,
                  38.788628
                ],
                [
                  106.280565,
                  38.787758
                ],
                [
                  106.281511,
                  38.787128
                ],
                [
                  106.282229,
                  38.78432
                ],
                [
                  106.282207,
                  38.781
                ],
                [
                  106.28338,
                  38.780024
                ],
                [
                  106.28638,
                  38.779845
                ],
                [
                  106.287986,
                  38.780007
                ],
                [
                  106.292322,
                  38.781145
                ],
                [
                  106.294746,
                  38.782457
                ],
                [
                  106.298272,
                  38.785138
                ],
                [
                  106.300475,
                  38.786395
                ],
                [
                  106.304804,
                  38.787234
                ],
                [
                  106.312133,
                  38.787733
                ],
                [
                  106.314251,
                  38.786987
                ],
                [
                  106.314251,
                  38.786182
                ],
                [
                  106.311443,
                  38.782129
                ],
                [
                  106.310981,
                  38.778694
                ],
                [
                  106.311621,
                  38.776231
                ],
                [
                  106.313078,
                  38.774454
                ],
                [
                  106.315922,
                  38.772442
                ],
                [
                  106.317166,
                  38.771871
                ],
                [
                  106.320457,
                  38.771066
                ],
                [
                  106.327352,
                  38.771334
                ],
                [
                  106.331752,
                  38.771658
                ],
                [
                  106.333486,
                  38.774023
                ],
                [
                  106.335341,
                  38.77543
                ],
                [
                  106.347589,
                  38.772843
                ],
                [
                  106.354214,
                  38.770307
                ],
                [
                  106.372652,
                  38.77188
                ],
                [
                  106.381488,
                  38.775247
                ],
                [
                  106.382675,
                  38.771334
                ],
                [
                  106.376647,
                  38.761219
                ],
                [
                  106.375019,
                  38.755008
                ],
                [
                  106.397737,
                  38.75251
                ],
                [
                  106.396955,
                  38.746264
                ],
                [
                  106.397034,
                  38.726223
                ],
                [
                  106.400765,
                  38.722704
                ],
                [
                  106.40503,
                  38.720409
                ],
                [
                  106.407767,
                  38.720589
                ],
                [
                  106.415607,
                  38.718767
                ],
                [
                  106.418834,
                  38.7193
                ],
                [
                  106.423441,
                  38.722205
                ],
                [
                  106.427862,
                  38.720563
                ],
                [
                  106.430414,
                  38.718853
                ],
                [
                  106.435709,
                  38.719659
                ],
                [
                  106.437714,
                  38.719527
                ],
                [
                  106.44065,
                  38.714621
                ],
                [
                  106.440124,
                  38.7128
                ],
                [
                  106.436889,
                  38.709204
                ],
                [
                  106.434821,
                  38.706418
                ],
                [
                  106.434373,
                  38.704179
                ],
                [
                  106.434501,
                  38.702293
                ],
                [
                  106.436122,
                  38.694392
                ],
                [
                  106.436989,
                  38.69347
                ],
                [
                  106.438226,
                  38.693577
                ],
                [
                  106.444069,
                  38.695544
                ],
                [
                  106.446265,
                  38.696555
                ],
                [
                  106.448682,
                  38.69728
                ],
                [
                  106.456359,
                  38.700313
                ],
                [
                  106.458036,
                  38.701901
                ],
                [
                  106.458854,
                  38.703159
                ],
                [
                  106.461285,
                  38.70801
                ],
                [
                  106.462237,
                  38.708799
                ],
                [
                  106.464363,
                  38.709072
                ],
                [
                  106.467341,
                  38.708534
                ],
                [
                  106.473091,
                  38.708547
                ],
                [
                  106.476809,
                  38.708227
                ],
                [
                  106.48015,
                  38.707519
                ],
                [
                  106.488118,
                  38.70362
                ],
                [
                  106.492177,
                  38.700834
                ],
                [
                  106.49502,
                  38.69833
                ],
                [
                  106.49662,
                  38.698163
                ],
                [
                  106.506578,
                  38.697792
                ],
                [
                  106.512343,
                  38.698099
                ],
                [
                  106.518605,
                  38.697754
                ],
                [
                  106.523268,
                  38.697916
                ],
                [
                  106.526687,
                  38.697685
                ],
                [
                  106.528145,
                  38.697289
                ],
                [
                  106.532232,
                  38.695339
                ],
                [
                  106.535004,
                  38.693214
                ],
                [
                  106.537641,
                  38.6903
                ],
                [
                  106.539831,
                  38.688461
                ],
                [
                  106.54573,
                  38.686515
                ],
                [
                  106.549526,
                  38.685533
                ],
                [
                  106.553393,
                  38.684842
                ],
                [
                  106.560245,
                  38.684164
                ],
                [
                  106.56542,
                  38.682841
                ],
                [
                  106.572137,
                  38.681697
                ],
                [
                  106.580667,
                  38.681932
                ],
                [
                  106.591763,
                  38.682623
                ],
                [
                  106.596277,
                  38.682559
                ],
                [
                  106.595858,
                  38.668544
                ],
                [
                  106.592844,
                  38.660472
                ],
                [
                  106.587107,
                  38.655725
                ],
                [
                  106.581897,
                  38.64927
                ],
                [
                  106.578741,
                  38.644842
                ],
                [
                  106.570133,
                  38.63667
                ],
                [
                  106.562641,
                  38.631575
                ],
                [
                  106.553386,
                  38.622526
                ],
                [
                  106.547564,
                  38.615658
                ],
                [
                  106.544757,
                  38.609306
                ],
                [
                  106.542162,
                  38.599933
                ],
                [
                  106.540115,
                  38.591678
                ],
                [
                  106.538722,
                  38.589298
                ],
                [
                  106.531222,
                  38.583243
                ],
                [
                  106.528145,
                  38.579679
                ],
                [
                  106.526702,
                  38.576312
                ],
                [
                  106.526417,
                  38.573512
                ],
                [
                  106.526609,
                  38.571294
                ],
                [
                  106.527761,
                  38.5684
                ],
                [
                  106.530263,
                  38.564631
                ],
                [
                  106.534137,
                  38.559668
                ],
                [
                  106.536092,
                  38.556205
                ],
                [
                  106.537172,
                  38.552571
                ],
                [
                  106.537655,
                  38.549057
                ],
                [
                  106.53779,
                  38.54367
                ],
                [
                  106.539113,
                  38.536273
                ],
                [
                  106.545069,
                  38.525338
                ],
                [
                  106.544764,
                  38.52382
                ],
                [
                  106.536781,
                  38.522515
                ],
                [
                  106.532324,
                  38.5213
                ],
                [
                  106.525515,
                  38.521951
                ],
                [
                  106.520155,
                  38.524226
                ],
                [
                  106.515471,
                  38.525021
                ],
                [
                  106.513487,
                  38.524979
                ],
                [
                  106.508839,
                  38.525612
                ],
                [
                  106.506365,
                  38.52521
                ],
                [
                  106.504844,
                  38.523118
                ],
                [
                  106.501595,
                  38.523319
                ],
                [
                  106.498518,
                  38.522562
                ],
                [
                  106.49743,
                  38.521185
                ],
                [
                  106.495411,
                  38.520531
                ],
                [
                  106.494224,
                  38.521993
                ],
                [
                  106.492575,
                  38.523067
                ],
                [
                  106.49013,
                  38.523028
                ],
                [
                  106.489675,
                  38.521698
                ],
                [
                  106.488957,
                  38.523396
                ],
                [
                  106.486533,
                  38.522874
                ],
                [
                  106.483185,
                  38.523092
                ],
                [
                  106.48214,
                  38.524748
                ],
                [
                  106.476169,
                  38.524371
                ],
                [
                  106.468947,
                  38.525676
                ],
                [
                  106.468443,
                  38.525547
                ],
                [
                  106.467782,
                  38.523495
                ],
                [
                  106.467618,
                  38.520839
                ],
                [
                  106.466069,
                  38.520638
                ],
                [
                  106.458477,
                  38.520334
                ],
                [
                  106.452847,
                  38.519928
                ],
                [
                  106.450608,
                  38.520672
                ],
                [
                  106.44232,
                  38.522447
                ],
                [
                  106.442825,
                  38.521514
                ],
                [
                  106.442498,
                  38.520595
                ],
                [
                  106.443806,
                  38.514222
                ],
                [
                  106.446123,
                  38.507685
                ],
                [
                  106.449194,
                  38.507673
                ],
                [
                  106.445789,
                  38.493721
                ],
                [
                  106.447722,
                  38.493619
                ],
                [
                  106.447338,
                  38.492061
                ],
                [
                  106.45252,
                  38.490739
                ],
                [
                  106.453153,
                  38.489267
                ],
                [
                  106.45193,
                  38.488159
                ],
                [
                  106.449649,
                  38.485065
                ],
                [
                  106.449691,
                  38.48447
                ],
                [
                  106.45144,
                  38.483199
                ],
                [
                  106.451482,
                  38.482352
                ],
                [
                  106.448305,
                  38.480666
                ],
                [
                  106.444858,
                  38.478192
                ],
                [
                  106.445057,
                  38.477337
                ],
                [
                  106.446997,
                  38.477919
                ],
                [
                  106.447104,
                  38.47562
                ],
                [
                  106.446862,
                  38.473207
                ],
                [
                  106.448334,
                  38.4725
                ],
                [
                  106.446116,
                  38.471649
                ],
                [
                  106.442995,
                  38.468764
                ],
                [
                  106.441901,
                  38.46846
                ],
                [
                  106.441694,
                  38.467411
                ],
                [
                  106.442697,
                  38.466868
                ],
                [
                  106.442284,
                  38.466307
                ],
                [
                  106.445782,
                  38.465425
                ],
                [
                  106.449066,
                  38.463893
                ],
                [
                  106.449947,
                  38.464565
                ],
                [
                  106.45176,
                  38.463367
                ],
                [
                  106.451177,
                  38.462177
                ],
                [
                  106.449059,
                  38.46067
                ],
                [
                  106.447943,
                  38.46049
                ],
                [
                  106.444644,
                  38.461779
                ],
                [
                  106.441815,
                  38.463992
                ],
                [
                  106.439398,
                  38.465545
                ],
                [
                  106.43782,
                  38.465811
                ],
                [
                  106.434757,
                  38.463358
                ],
                [
                  106.432774,
                  38.460576
                ],
                [
                  106.431956,
                  38.458337
                ],
                [
                  106.432212,
                  38.45427
                ],
                [
                  106.431885,
                  38.450815
                ],
                [
                  106.432184,
                  38.448782
                ],
                [
                  106.426952,
                  38.450242
                ],
                [
                  106.421962,
                  38.451915
                ],
                [
                  106.425985,
                  38.458097
                ],
                [
                  106.42128,
                  38.460165
                ],
                [
                  106.419403,
                  38.459381
                ],
                [
                  106.418337,
                  38.459437
                ],
                [
                  106.417662,
                  38.46094
                ],
                [
                  106.414925,
                  38.460443
                ],
                [
                  106.41543,
                  38.462558
                ],
                [
                  106.412664,
                  38.462592
                ],
                [
                  106.412202,
                  38.459518
                ],
                [
                  106.409075,
                  38.457879
                ],
                [
                  106.400509,
                  38.457404
                ],
                [
                  106.395903,
                  38.455238
                ],
                [
                  106.394148,
                  38.454163
                ],
                [
                  106.391887,
                  38.45406
                ],
                [
                  106.38716,
                  38.454758
                ],
                [
                  106.381118,
                  38.4565
                ],
                [
                  106.375503,
                  38.456239
                ],
                [
                  106.373377,
                  38.456282
                ],
                [
                  106.380194,
                  38.464706
                ],
                [
                  106.381339,
                  38.465794
                ],
                [
                  106.385191,
                  38.468734
                ],
                [
                  106.385731,
                  38.469436
                ],
                [
                  106.387395,
                  38.473921
                ],
                [
                  106.38758,
                  38.476476
                ],
                [
                  106.386954,
                  38.478809
                ],
                [
                  106.385845,
                  38.480011
                ],
                [
                  106.382966,
                  38.481693
                ],
                [
                  106.380948,
                  38.48183
                ],
                [
                  106.378581,
                  38.481239
                ],
                [
                  106.374124,
                  38.479763
                ],
                [
                  106.36762,
                  38.478813
                ],
                [
                  106.364044,
                  38.478766
                ],
                [
                  106.357185,
                  38.478907
                ],
                [
                  106.356467,
                  38.480221
                ],
                [
                  106.361087,
                  38.484671
                ],
                [
                  106.361101,
                  38.486336
                ],
                [
                  106.360611,
                  38.489776
                ],
                [
                  106.359758,
                  38.493499
                ],
                [
                  106.357732,
                  38.495108
                ],
                [
                  106.355244,
                  38.49557
                ],
                [
                  106.354306,
                  38.496943
                ],
                [
                  106.354754,
                  38.498372
                ],
                [
                  106.358429,
                  38.501919
                ],
                [
                  106.361947,
                  38.504828
                ],
                [
                  106.363632,
                  38.50671
                ],
                [
                  106.364599,
                  38.508772
                ],
                [
                  106.364535,
                  38.510945
                ],
                [
                  106.363461,
                  38.513524
                ],
                [
                  106.361706,
                  38.5129
                ],
                [
                  106.358628,
                  38.511078
                ],
                [
                  106.355273,
                  38.511078
                ],
                [
                  106.352287,
                  38.51213
                ],
                [
                  106.349217,
                  38.512648
                ],
                [
                  106.343736,
                  38.512938
                ],
                [
                  106.338483,
                  38.513533
                ],
                [
                  106.337836,
                  38.515026
                ],
                [
                  106.337715,
                  38.518567
                ],
                [
                  106.334311,
                  38.523841
                ],
                [
                  106.332128,
                  38.525787
                ],
                [
                  106.329797,
                  38.525513
                ],
                [
                  106.324814,
                  38.52358
                ],
                [
                  106.322319,
                  38.521929
                ],
                [
                  106.318879,
                  38.520103
                ],
                [
                  106.316981,
                  38.520047
                ],
                [
                  106.31472,
                  38.521249
                ],
                [
                  106.312311,
                  38.521951
                ],
                [
                  106.309908,
                  38.521258
                ],
                [
                  106.308643,
                  38.519551
                ],
                [
                  106.305409,
                  38.513888
                ],
                [
                  106.303639,
                  38.511488
                ],
                [
                  106.302167,
                  38.510898
                ],
                [
                  106.295649,
                  38.506783
                ],
                [
                  106.281006,
                  38.502826
                ],
                [
                  106.261764,
                  38.501037
                ],
                [
                  106.262816,
                  38.50357
                ],
                [
                  106.264479,
                  38.506398
                ],
                [
                  106.265666,
                  38.507446
                ],
                [
                  106.268375,
                  38.508421
                ],
                [
                  106.278682,
                  38.512797
                ],
                [
                  106.283423,
                  38.51598
                ],
                [
                  106.287098,
                  38.519299
                ],
                [
                  106.290631,
                  38.5234
                ],
                [
                  106.29417,
                  38.527117
                ],
                [
                  106.296673,
                  38.529328
                ],
                [
                  106.298997,
                  38.531723
                ],
                [
                  106.299651,
                  38.534032
                ],
                [
                  106.299743,
                  38.536055
                ],
                [
                  106.300554,
                  38.543832
                ],
                [
                  106.30049,
                  38.546949
                ],
                [
                  106.300084,
                  38.553593
                ],
                [
                  106.292415,
                  38.570585
                ],
                [
                  106.289131,
                  38.577718
                ],
                [
                  106.286437,
                  38.583141
                ],
                [
                  106.283935,
                  38.588948
                ],
                [
                  106.282755,
                  38.590576
                ],
                [
                  106.280487,
                  38.592353
                ],
                [
                  106.273535,
                  38.596417
                ],
                [
                  106.25853,
                  38.605662
                ],
                [
                  106.248457,
                  38.596887
                ],
                [
                  106.24201,
                  38.595613
                ],
                [
                  106.23879,
                  38.598818
                ],
                [
                  106.237774,
                  38.600544
                ],
                [
                  106.237511,
                  38.603299
                ],
                [
                  106.236395,
                  38.607883
                ],
                [
                  106.234497,
                  38.609267
                ],
                [
                  106.22441,
                  38.614064
                ],
                [
                  106.224275,
                  38.615188
                ],
                [
                  106.229507,
                  38.615256
                ],
                [
                  106.232883,
                  38.615914
                ],
                [
                  106.236395,
                  38.619207
                ],
                [
                  106.233552,
                  38.621176
                ],
                [
                  106.231661,
                  38.625011
                ],
                [
                  106.223899,
                  38.639432
                ],
                [
                  106.222562,
                  38.642011
                ],
                [
                  106.228718,
                  38.656096
                ],
                [
                  106.229109,
                  38.657266
                ],
                [
                  106.233424,
                  38.667263
                ],
                [
                  106.241427,
                  38.685414
                ],
                [
                  106.242529,
                  38.687466
                ],
                [
                  106.241747,
                  38.687449
                ],
                [
                  106.210492,
                  38.688021
                ],
                [
                  106.209725,
                  38.681659
                ],
                [
                  106.205197,
                  38.681112
                ],
                [
                  106.197982,
                  38.681211
                ],
                [
                  106.191208,
                  38.681181
                ],
                [
                  106.183126,
                  38.682303
                ],
                [
                  106.178612,
                  38.683532
                ],
                [
                  106.176281,
                  38.684001
                ],
                [
                  106.166393,
                  38.687027
                ],
                [
                  106.164424,
                  38.687432
                ],
                [
                  106.156925,
                  38.689527
                ],
                [
                  106.153861,
                  38.689374
                ],
                [
                  106.15298,
                  38.686967
                ],
                [
                  106.152674,
                  38.682853
                ],
                [
                  106.141365,
                  38.683946
                ],
                [
                  106.137377,
                  38.677737
                ],
                [
                  106.131207,
                  38.667536
                ],
                [
                  106.129452,
                  38.664471
                ],
                [
                  106.120175,
                  38.645884
                ],
                [
                  106.105717,
                  38.654273
                ],
                [
                  106.101687,
                  38.654559
                ],
                [
                  106.098986,
                  38.655597
                ],
                [
                  106.09337,
                  38.659161
                ],
                [
                  106.090818,
                  38.66031
                ],
                [
                  106.088999,
                  38.661458
                ],
                [
                  106.086731,
                  38.662376
                ],
                [
                  106.084613,
                  38.662721
                ],
                [
                  106.080519,
                  38.662837
                ],
                [
                  106.07373,
                  38.662845
                ],
                [
                  106.063125,
                  38.662674
                ],
                [
                  106.058362,
                  38.662734
                ],
                [
                  106.055441,
                  38.662452
                ],
                [
                  106.0506,
                  38.661304
                ],
                [
                  106.048262,
                  38.662102
                ],
                [
                  106.046136,
                  38.663528
                ],
                [
                  106.043293,
                  38.664335
                ],
                [
                  106.039845,
                  38.664561
                ],
                [
                  106.037201,
                  38.664497
                ],
                [
                  106.031408,
                  38.663524
                ],
                [
                  106.026432,
                  38.663229
                ],
                [
                  106.024158,
                  38.663737
                ],
                [
                  106.015308,
                  38.667225
                ],
                [
                  106.010254,
                  38.668411
                ],
                [
                  105.997132,
                  38.67011
                ],
                [
                  105.989519,
                  38.671578
                ],
                [
                  105.982987,
                  38.672487
                ],
                [
                  105.980933,
                  38.672538
                ],
                [
                  105.97676,
                  38.67311
                ],
                [
                  105.973469,
                  38.674024
                ],
                [
                  105.971571,
                  38.674818
                ],
                [
                  105.967171,
                  38.67859
                ],
                [
                  105.965273,
                  38.681966
                ],
                [
                  105.962494,
                  38.681266
                ],
                [
                  105.958542,
                  38.679777
                ],
                [
                  105.956999,
                  38.679883
                ],
                [
                  105.956196,
                  38.680681
                ],
                [
                  105.954291,
                  38.684684
                ],
                [
                  105.953409,
                  38.685836
                ],
                [
                  105.949756,
                  38.686856
                ],
                [
                  105.947986,
                  38.687714
                ],
                [
                  105.947339,
                  38.690291
                ],
                [
                  105.944844,
                  38.693837
                ],
                [
                  105.941048,
                  38.696806
                ],
                [
                  105.936286,
                  38.699955
                ],
                [
                  105.932774,
                  38.701555
                ],
                [
                  105.925979,
                  38.702519
                ],
                [
                  105.917634,
                  38.704699
                ],
                [
                  105.913902,
                  38.706354
                ],
                [
                  105.90953,
                  38.708645
                ],
                [
                  105.904768,
                  38.71077
                ],
                [
                  105.899728,
                  38.712484
                ],
                [
                  105.89294,
                  38.714037
                ],
                [
                  105.889947,
                  38.714609
                ],
                [
                  105.885462,
                  38.715982
                ],
                [
                  105.886024,
                  38.717048
                ],
                [
                  105.890779,
                  38.719966
                ],
                [
                  105.893544,
                  38.721386
                ],
                [
                  105.895669,
                  38.722883
                ],
                [
                  105.898662,
                  38.726483
                ],
                [
                  105.900119,
                  38.727677
                ],
                [
                  105.904569,
                  38.730027
                ],
                [
                  105.90582,
                  38.73128
                ],
                [
                  105.907938,
                  38.737293
                ],
                [
                  105.907725,
                  38.741361
                ],
                [
                  105.905607,
                  38.748801
                ],
                [
                  105.90378,
                  38.751896
                ],
                [
                  105.901811,
                  38.753158
                ],
                [
                  105.90115,
                  38.754476
                ],
                [
                  105.901079,
                  38.755844
                ],
                [
                  105.901882,
                  38.758704
                ],
                [
                  105.903716,
                  38.763922
                ],
                [
                  105.905329,
                  38.766718
                ],
                [
                  105.907149,
                  38.768265
                ],
                [
                  105.907298,
                  38.770094
                ],
                [
                  105.901889,
                  38.774454
                ],
                [
                  105.89911,
                  38.777258
                ],
                [
                  105.897361,
                  38.780237
                ],
                [
                  105.896629,
                  38.784874
                ],
                [
                  105.896849,
                  38.786936
                ],
                [
                  105.897439,
                  38.788709
                ],
                [
                  105.898605,
                  38.789565
                ],
                [
                  105.902117,
                  38.790076
                ],
                [
                  105.904889,
                  38.790187
                ],
                [
                  105.907675,
                  38.790592
                ],
                [
                  105.909644,
                  38.791278
                ],
                [
                  105.912651,
                  38.794601
                ],
                [
                  105.918281,
                  38.79654
                ],
                [
                  105.920264,
                  38.79752
                ],
                [
                  105.922311,
                  38.798947
                ],
                [
                  105.92642,
                  38.802837
                ],
                [
                  105.930073,
                  38.803297
                ],
                [
                  105.933009,
                  38.803416
                ],
                [
                  105.934843,
                  38.803237
                ],
                [
                  105.936371,
                  38.803932
                ],
                [
                  105.935284,
                  38.808051
                ],
                [
                  105.936016,
                  38.810053
                ],
                [
                  105.939321,
                  38.813836
                ],
                [
                  105.942257,
                  38.816873
                ],
                [
                  105.943643,
                  38.820187
                ],
                [
                  105.946657,
                  38.824028
                ],
                [
                  105.951064,
                  38.827244
                ],
                [
                  105.95304,
                  38.829015
                ],
                [
                  105.957525,
                  38.831937
                ],
                [
                  105.961783,
                  38.833317
                ],
                [
                  105.964129,
                  38.834696
                ],
                [
                  105.966048,
                  38.836872
                ],
                [
                  105.967214,
                  38.838767
                ],
                [
                  105.968614,
                  38.842595
                ],
                [
                  105.969645,
                  38.843864
                ],
                [
                  105.970903,
                  38.844613
                ],
                [
                  105.977002,
                  38.846048
                ],
                [
                  105.981992,
                  38.848752
                ],
                [
                  105.984856,
                  38.851097
                ],
                [
                  105.98905,
                  38.853911
                ],
                [
                  105.990955,
                  38.855567
                ],
                [
                  105.992505,
                  38.857581
                ],
                [
                  105.994715,
                  38.862272
                ],
                [
                  105.99635,
                  38.86732
                ],
                [
                  105.997807,
                  38.868861
                ],
                [
                  106.001205,
                  38.870529
                ],
                [
                  106.003117,
                  38.873049
                ],
                [
                  106.003309,
                  38.875169
                ]
              ]
            ]
          ]
        }
      }
    ]
  }

  export default {
    name: 'CenterMap',
    components: {},
    data() {
      return {
        scene: null,
        qyCarList: [], //所有清运车辆
        carPointData: {},
        doingCarList: [], //清运中车辆
        carDoingPointData: {}
      }
    },
    mounted() {
      window.dpInit = true
      this.scene = new Scene({
        id: 'map',
        map: new GaodeMap({
          pitch: 0,
          /*style: 'dark',*/
          style: 'amap://styles/darkblue',
          center: [106.39297485351561, 38.54198948702892],
          zoom: 10.5
        })
      })
      this.init()
    },
    methods: {
      click() {
        this.$router.push('/dispatch/panel/xq')
      },
      init() {
        this.getCarStatus()
        // this.loadPoint()
        // this.loadPoint2()
        this.loadPolygon()
        /*this.loadLine()*/
      },
      //加载清运车辆是否清运中显示
      getCarStatus() {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchDatavApi.getCarStatus,
          noTips: true,
          data: {
            useType: 'QY'
          },
          loading: 'no',
          success: (data) => {
            this.qyCarList = data.body
            this.doingCarList = []
            var carCoordinates = []
            var carDoingCoordinates = []
            for (let i = 0; i < this.qyCarList.length; i++) {
              if (this.qyCarList[i].longitude !== '0' && this.qyCarList[i].latitude !== '0') {
                let catItem = []
                catItem.push(parseFloat(this.qyCarList[i].longitude))
                catItem.push(parseFloat(this.qyCarList[i].latitude))
                // carCoordinates.push(catItem)
                carCoordinates.push([parseFloat(this.qyCarList[i].longitude), parseFloat(this.qyCarList[i].latitude)])
              }
              if (this.qyCarList[i].status === 'doing') {
                if (this.qyCarList[i].longitude !== '0' && this.qyCarList[i].latitude !== '0') {
                  let catDoingItem = []
                  catDoingItem.push(parseFloat(this.qyCarList[i].longitude))
                  catDoingItem.push(parseFloat(this.qyCarList[i].latitude))
                  // carDoingCoordinates.push(catDoingItem)
                  carDoingCoordinates.push([parseFloat(this.qyCarList[i].longitude), parseFloat(this.qyCarList[i].latitude)])
                }
              }
            }
            this.carPointData = {
              'type': 'FeatureCollection',
              'features': [{
                'type': 'Feature',
                'properties': {},
                'geometry': {
                  'type': 'MultiPoint',
                  'coordinates': carCoordinates
                }
              }]
            }
            this.carDoingPointData = {
              'type': 'FeatureCollection',
              'features': [{
                'type': 'Feature',
                'properties': {},
                'geometry': {
                  'type': 'MultiPoint',
                  'coordinates': carDoingCoordinates
                }
              }]
            }
            this.loadDoingCarPoint()
            this.loadCarPoint()
          },
          error: () => {
          }
        })
      },
      loadCarPoint() {
        this.scene.addImage('local', 'http://sangefile.fangshuoit.com/o_1ep2pcnam9d51oba1haa1uac1un3e.png')
        const pointLayer = new PointLayer({})
          .source(this.carPointData)
          .shape('local')
          .active(true)
          .animate(false)
          .size(15)
          // .color('#F06154')
          .style({
            opacity: 1
          })
        this.scene.addLayer(pointLayer)
      },
      loadDoingCarPoint() {
        const pointLayer = new PointLayer({})
          .source(this.carDoingPointData)
          .shape('circle')
          .active(true)
          .animate(true)
          .size(50)
          .color('#4cfd47')
          .style({
            opacity: 1,
            offsets: [0, 0]
          })
        this.scene.addLayer(pointLayer)
      },
      loadPolygon() {
        const polygonLayer = new PolygonLayer({
          autoFit: true
        })
          .source(polygonData)
          .color(
            'name',
            [
              'rgb(49,130,189)',
              'rgb(8,81,156)'
            ]
          )
          .shape('fill')
          .style({
            opacity: 0.1
          })
        this.scene.addLayer(polygonLayer)
        const polygonLayerLine = new PolygonLayer({
          autoFit: true
        })
          .source(polygonData)
          .color(
            'name',
            [
              'rgb(8,81,156)'
            ]
          )
          .shape('line')
          .size(1.5)
          .style({
            opacity: 0.6
          })
        this.scene.addLayer(polygonLayer)
        this.scene.addLayer(polygonLayerLine)
      },
      loadPoint() {
        this.scene.on('loaded', () => {
          fetch(
            '/mapdata/ningxia-points.csv'
          )
            .then(res => res.text())
            .then(data => {
              const pointLayer = new PointLayer({})
                .source(data, {
                  parser: {
                    type: 'csv',
                    x: 'lon',
                    y: 'lat'
                  }
                })
                .shape('circle')
                .active(true)
                .animate(true)
                .size(30)
                .color('#4cfd47')
                .style({
                  opacity: 1
                })
              this.scene.addLayer(pointLayer)
            })
        })
      },
      loadPoint2() {
        const pointLayer = new PointLayer({})
          .source(pointData)
          .shape('circle')
          .active(true)
          .animate(true)
          .size(34)
          .color('#4cfd47')
          .style({
            opacity: 1
          })
        this.scene.addLayer(pointLayer)
      },
      loadLine() {
        /*
        duration 动画时间 单位(s)秒
        interval 轨迹间隔, 取值区间 0 - 1
        trailLength 轨迹长度 取值区间 0 - 1*/
        const lineLayer = new LineLayer({})
          .source(lineData)
          .active(true)
          .animate({
            duration: 3,
            interval: 2,
            trailLength: 0.4
          })
          .color('#4cfd47')
          .size(1.5)
          .style({
            opacity: 1
          })
        this.scene.addLayer(lineLayer)
      }
    }
  }
</script>

<style lang="less">
  .strong {
    color: #09ff99;
    font-size: 13px
  }
</style>